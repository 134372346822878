/* eslint  jsx-a11y/img-redundant-alt: 0 */

import React, { Fragment } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Spring } from 'react-spring'
import logo from './picture_north_logo_blue.svg'
import logoType from '../../images/Picture_North.svg'
// import logoHorizontal from '../../images/PictureNorth_Horizontal.svg'
import aboutIcon from './about.svg'
import directorIcon from './directors.svg'
import contactIcon from './contact.svg'
import teamIcon from './team.svg'
import editorialIcon from './editorial.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './navbar.scss'



const toggleMenu = () => {
	let menu = document.getElementsByClassName('navbar')

	if(menu[0].classList.contains('opened')) {
		menu[0].classList.remove('opened')
	} else {
		menu[0].classList.add('opened')
	}

}

const Content = (data) => {

	
	return (
		
		<Fragment>
			<button onClick={ () => {toggleMenu()}} className='menu-toggle'>
				<FontAwesomeIcon icon='bars' />
			</button>
			<StaticQuery
				query={graphql`
					query {
						directors: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/directors/"}}, sort: { order: ASC, fields: [frontmatter___date] }) {
							edges {
								node {
									frontmatter {
										title
									}
									fields {
									slug
									}
									id
								}
							}
						}
						editorial: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/editors/"}}, sort: { order: ASC, fields: [frontmatter___date] }) {
							edges {
								node {
									frontmatter {
										title
									}
									fields {
									slug
									}
									id
								}
							}
						}
					}
				`}
				render={data => (

					<div className="navbar">
						<Link className="logo" to="/"><img className='logo-main' src={logo} alt='Picture North Logo' /><img className='logo-type' src={logoType} alt='Picture-North Logo' /></Link>
						<ul>
							<li className="subnav">
								<Link onClick={ () => {toggleMenu()}} to="/directors/"><img src={directorIcon} alt='Director Icon'/><span>Directors</span></Link>
								<ul>
								{
									data.directors.edges.map(({node}) => (
										<li key={node.id}><Link onClick={ () => {toggleMenu()}} to={node.fields.slug}>{node.frontmatter.title}</Link></li>
									))
								}
								</ul>
							</li>
							<li className="subnav editorial">
								<Link onClick={ () => {toggleMenu()}} to="/editors/"><img src={editorialIcon} alt='Editorial Icon' /><span>Editors</span></Link>
								<ul>
									{
										data.editorial.edges.map(({node}) => (
											<li key={node.id}><Link onClick={ () => {toggleMenu()}} to={node.fields.slug}>{node.frontmatter.title}</Link></li>
										))
									}									
								</ul>
							</li>
							<li>
								<Link onClick={ () => {toggleMenu()}} to="/team/"><img src={teamIcon} alt='Team Icon' /><span>Team</span></Link>
							</li>
							<li>
								<Link onClick={ () => {toggleMenu()}} to="/about/"><img src={aboutIcon} alt='About Icon' /><span>About</span></Link>
							</li>
							<li>
								<Link onClick={ () => {toggleMenu()}} to="/contact/"><img src={contactIcon} alt='Contact Icon' /><span>Contact</span></Link>
							</li>
						</ul>
						<ul className="social-icons">
							<li><a target='_blank' rel='noopener noreferrer' href="https://www.facebook.com/picturenorth/"><FontAwesomeIcon icon={['fab', 'facebook-square']} /></a></li>
							<li><a target='_blank' rel='noopener noreferrer' href="https://www.instagram.com/picture.north/"><FontAwesomeIcon icon={['fab', 'instagram']} /></a></li>
							<li><a target='_blank' rel='noopener noreferrer' href="https://vimeo.com/picturenorth"><FontAwesomeIcon icon={['fab', 'vimeo']} /></a></li>
							<li><a target='_blank' rel='noopener noreferrer' href="https://www.linkedin.com/company/picturenorth/"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></li>
							<li><a target='_blank' rel='noopener noreferrer' href="mailto:hello@picturenorth.com"><FontAwesomeIcon icon='envelope' /></a></li>
						</ul>
					</div>
				)}
			>

			
			</StaticQuery>

		</Fragment>
	)
}

class Navbar extends React.Component {
	constructor(props){
		super(props)
		this.state = { toggle: true }
	}
	toggle = () => this.setState(state => ({ toggle: !state.toggle }))
	render (){
		const toggle = this.state.toggle
		return (
			<Spring 
				from={{ background: 'black' }}
				to={{
					background: toggle ? 'blue' : 'red',
					width: toggle ? '200px' : '50px',
				}}
				toggle={this.toggle}
				children={Content}
			/>
		)
	}
}

export default Navbar

// export const navMarkdown = graphql`
// 	query navBarQuery {
// 		site {
// 			siteMetadata {
// 				siteUrl
// 			}

// 		}
// 		directors: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/directors/"}}, sort: { order: ASC, fields: [frontmatter___date] }) {
// 			edges {
// 				node {
// 					frontmatter {
// 						title
// 					}
// 					fields {
// 					slug
// 					}
// 					id
// 				}
// 			}
// 		}
// 		editorial: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/editorial/"}}, sort: { order: ASC, fields: [frontmatter___date] }) {
// 			edges {
// 				node {
// 					frontmatter {
// 						title
// 					}
// 					fields {
// 					slug
// 					}
// 					id
// 				}
// 			}
// 		}
// 	}
// `