import React from 'react'
import Navbar from '../components/navbar/navbar.js'
import Helmet from 'react-helmet'

if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  window.navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(r => r.unregister())
  })
}

const LayoutWrap = (props) => (
  <div>
    <Helmet>
      <meta property="og:url" content={props.location.href} />
      <meta name="p:domain_verify" content="ab9e2ae8628175b6ec07a7ce2bde236a"/>
    </Helmet>

    <Navbar />
    {props.children}
  </div>
)

export default LayoutWrap