exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-directors-js": () => import("./../../../src/pages/directors.js" /* webpackChunkName: "component---src-pages-directors-js" */),
  "component---src-pages-editors-js": () => import("./../../../src/pages/editors.js" /* webpackChunkName: "component---src-pages-editors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-director-template-js": () => import("./../../../src/templates/director-template.js" /* webpackChunkName: "component---src-templates-director-template-js" */),
  "component---src-templates-interview-template-js": () => import("./../../../src/templates/interview-template.js" /* webpackChunkName: "component---src-templates-interview-template-js" */)
}

